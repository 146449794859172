import chest from './armor.svg';
import healthPotion from './bottle.svg';
import gold from './coin.svg';
import hands from './gauntlet.svg';
import head from './helmet.svg';
import amulet from './necklace.svg';
import sword from './sword.svg';
import legs from './legs.svg';
import feet from './feet.svg';
import torch from './torch.svg';

export default {
	chest,
	healthPotion,
	gold,
	hands,
	head,
	amulet,
	sword,
	legs,
	feet,
	torch
};